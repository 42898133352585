import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import loadable from '@loadable/component';
import { artDesign } from '../data/data';

const Grid = loadable(() => import('../components/grid'));
const CardWithoutImage = loadable(() => import('../components/card-without-image'));

const ArtDesign = ({ data }) => {
  const metaData = {
    title: 'Арт дизайн'
  };
  return (
    <Layout metaData={metaData}>
      <Grid mode="dark">
        {artDesign.map(page => (
          <CardWithoutImage
            key={page.id}
            title={page.title}
            bg={data[page.id] ? data[page.id].childImageSharp.fluid.src : ''}
            url={page.slug}
          />
        ))}
      </Grid>
    </Layout>
  );
};

export default ArtDesign;

export const query = graphql`
  query artDesign {
    architectureAccessoires: file(name: { eq: "accessoires-cover" }) {
      ...mediumImage
    }
    babyAccessoires: file(name: { eq: "01-baby-accessoires" }) {
      ...mediumImage
    }
    houseAccessoires: file(name: { eq: "01-house-accessoires" }) {
      ...mediumImage
    }
    easterInHouse: file(name: { eq: "01-великден-вкъщи" }) {
      ...mediumImage
    }
    cards: file(name: { eq: "29-нова-година" }) {
      ...mediumImage
    }
  }
`;
